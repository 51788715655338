import React from 'react';
import Modal from 'react-bootstrap/Modal'

// import assets
import "./styles.scss"

export default function ModalComponent (props) {
  const {
    children,
    title,
    closeOutside,
    closeLink,
    closePosition = 'relative',
    headerSideSize = 'md',
    bodySideSize = 'sm',
    onHide,
    ...rest
  } = props;

  return (
    <Modal
      onHide={onHide}
      {...rest}
    >
      <Modal.Header
        bsPrefix={`modal-header side-${headerSideSize} ${closeOutside && 'close-outside'} pos-${closePosition}`}
        closeButton
      >
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body
        bsPrefix={`modal-body side-${bodySideSize}`}
      >
        {children}
        {closeLink && (
          <div className="close-link">
            <button type="button" className="button button--link" onClick={onHide}>Close</button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}