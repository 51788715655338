import React from 'react';
import CasesSlider from "./components/Slider/index";

// import assets
import "./styles.scss"

export default function CasesCarousel () {
  return (
    <section className="carousel-section section">
      <div className="section__content carousel-section__content">
        <header>
          <h2 className="title title--general title--bold">Case Studies</h2>
          <h4 className="title title--sub">Learn more about our approach and prototyping process from our customers</h4>
        </header>
        <div className="carousel carousel-section__carousel">
          <CasesSlider />
        </div>
      </div>
    </section>
  )
}