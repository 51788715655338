import React from 'react';

// import sections
import MainScreen from "../../screens/Main";
import FigmaExample from "../../screens/FigmaExample";
import GoingDoSection from "../../screens/GoingDo";
import PrototypeExamples from "../../screens/PrototypeExamples";
import VideoSection from "../../screens/Video";
import CasesCarousel from "../../screens/CasesCarousel";
import Prices from "../../screens/Prices";
import ContactUs from "../../screens/ContactUs";
import Footer from "../../components/Footer";

function Home() {
  return (
    <div className="app">
      <main className="main">
        <MainScreen /> {/* prototype request section */}
        <FigmaExample/>  {/* figma prototype example secion */}
        <GoingDoSection/> {/* section with offers */}
        <PrototypeExamples/> {/* section with prototype examples from figma */}
        <VideoSection/> {/* section with video embed */}
        <CasesCarousel/> {/* section with cases */}
        <Prices/> {/* section with price list cards */}
        <ContactUs background={'red'} analyticsFormID="home-contactUs" /> {/* contact us section */}
      </main>

      <Footer/>
    </div>
  );
}

export default Home;
