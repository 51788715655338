import React, { Component } from "react";

// react-router components
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slick-styles.scss';
import './styles.scss';

// import slider images
import Photo from "../../../../assets/images/slide_1.png";
import Yummi from "../../../../assets/images/slide_2.png";
import DesktopPrev from "../../../../assets/images/slide_preview_1.png";
import YummiPrev from "../../../../assets/images/slide_preview_2.png";

class CasesSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  render() {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      accessibility: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: true
          }
        }
      ]
    };
    return (
      <Slider ref={c => (this.slider = c)} {...settings}>
        <div className="carousel__item">
          <div className="carousel__main-img">
            <img src={Photo} className="" alt="carousel 1" />
          </div>
          <div className="info-card carousel__card">
            <h5 className="title title--bold info-card__title">Auction Marketplace</h5>
            <div className="info-card__description">
              <p>We received a request from stakeholder that would like to develop low fidelity prototype for auction marketplace.</p>
              <p>
                The main idea is creating an application where user (as a seller)
                can present their products for sale at auctions' lots from one side, and from the other side -
                take part in the lots of other users (as a customer) to buy their products.
              </p>
            </div>
            <div className="info-card__buttons-panel">
              <Link to="/auction-case" className="button button--default info-card__button">case study</Link>
            </div>
            <div className="carousel__stepper-panel">
              <div className="carousel__next-item-prev">
                <img src={YummiPrev} alt="next carousel preview img" />
              </div>
              <span onClick={this.next} className="carousel__next-item-button">
                next case study
              </span>
            </div>
            <div className="carousel__item-shadow" />
          </div>
        </div>

        <div className="carousel__item">
          <div className="carousel__main-img">
            <img src={Yummi} className="" alt="carousel 2" />
          </div>
          <div className="info-card carousel__card">
            <h5 className="title title--bold info-card__title">Yummies App</h5>
            <div className="info-card__description">
              <p>
                Stakeholder would like to develop a mobile application that will allow employees to track money spent
                on snacks and view calories consumption during a particular period.
                Later they would like to implement online payment for snacks.
              </p>
              <p>To understand how much app development will cost they request from Brocoders - Full Documentation and High Fidelity prototype.</p>
            </div>
            <div className="info-card__buttons-panel">
              <Link to="/yummies-case" className="button button--default info-card__button">case study</Link>
            </div>
            <div className="carousel__stepper-panel">
              <div className="carousel__next-item-prev">
                <img src={DesktopPrev} alt="next carousel preview img" />
              </div>
              <span onClick={this.next} className="carousel__next-item-button">
                next case study
              </span>
            </div>
            <div className="carousel__item-shadow" />
          </div>
        </div>
      </Slider>
    );
  }
}

export default CasesSlider;
