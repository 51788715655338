import superagent from 'superagent';

const methods = ['get', 'post', 'put', 'patch', 'del'];

function formatUrl (path, uri = '') {
  const adjustedPath = path[0] !== '/' ? '/' + path : path;
  return uri + '/api' + adjustedPath
}

class _ApiClient {
  constructor(req) {
    if (_ApiClient.instance) {
      return _ApiClient.instance
    }

    methods.forEach((method) =>
      this[method] = (path, { params, data } = {}, uri) =>
        new Promise((resolve, reject) => {
          const request = superagent[method](formatUrl(path, uri));

          if (params) {
            request.query(params)
          }

          if (data) {
            request.send(data)
          }

          request.end((err, { body } = {}) => err ? reject(body || err) : resolve(body))
      }))

    _ApiClient.instance = this
  }
}

const ApiClient = _ApiClient;

export default ApiClient;
