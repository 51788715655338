import React from 'react';
import './styles.scss';

export default function Footer () {
  return (
    <footer className="section section--black footer">
      <span className="footer__copyring">Copyright © 2019 Brocoders, All rights reserved</span>
    </footer>
  );
}

