import React from 'react';

// import assets
import "./styles.scss"

export default function CasesDescription (props) {
  const { mainDescription, subDescription } = props;

  return (
    <section className="cases-description section section--red">
      <div className="cases-description__content section__content">
        <h4 className="title cases-description__subtitle">{mainDescription}</h4>
        <h4 className="title cases-description__subtitle">{subDescription}</h4>
      </div>
    </section>
  )
}
