import React from 'react';

// import assets
import RequirementsIcon from "../../components/svg/Icons/RequirementsIcon.svg";
import SpecifyIcon from "../../components/svg/Icons/SpecifyIcon.svg";
import HightFidelityIcon from "../../components/svg/Icons/HightFidelity.svg";
import ProjectBudgetIcon from "../../components/svg/Icons/ProjectBudget.svg";
import "./styles.scss"

export default function GoingDoSection () {
  return (
    <section className="offers section section--white">
      <div className="offers__content section__content">
        <header>
          <h2 className="title title--general title--bold offers__title">What we are going to do:</h2>
        </header>
        <div className="capability-list">
          <div className="capability capability-list__item">
            <div className="capability__image">
              <img src={RequirementsIcon} alt="requirments icon"/>
            </div>
            <div className="capability__content">
              <h4 className="capability__title title title--bold">Requirements elicitation</h4>
              <ul className="list capability__description">
                <li>Study the business processes</li>
                <li>Analyze the target audience</li>
                <li>Research competitors</li>
              </ul>
              <ul className="budges">
                <li>Business Analyst</li>
                <li>Stakeholder</li>
              </ul>
              <div className="estimate-time">20 h</div>
            </div>
          </div>

          <div className="capability capability-list__item">
            <div className="capability__image">
              <img src={SpecifyIcon} alt="specify icon"/>
            </div>
            <div className="capability__content">
              <h4 className="capability__title title title--bold">Specify and model requirements</h4>
              <ul className="list capability__description">
                <li>User Stories and user stories map</li>
                <li>Feature decomposition</li>
                <li>Wireframes of main pages</li>
              </ul>
              <ul className="budges">
                <li>Business Analyst</li>
                <li>UX designer</li>
              </ul>
              <div className="estimate-time"><span>40-60 h</span></div>
            </div>
          </div>

          <div className="capability capability-list__item">
            <div className="capability__image">
              <img src={HightFidelityIcon} alt="prototype icon"/>
            </div>
            <div className="capability__content">
              <h4 className="capability__title title title--bold">High fidelity prototype</h4>
              <ul className="list capability__description">
                <li>UX and UI prototype</li>
                <li>Pages Interactions</li>
              </ul>
              <ul className="budges">
                <li>Business Analyst</li>
                <li>UX designer</li>
                <li>Stakeholder</li>
              </ul>
              <div className="estimate-time"><span>15 days</span></div>
            </div>
          </div>

          <div className="capability capability-list__item">
            <div className="capability__image">
              <img src={ProjectBudgetIcon} alt="budget icon"/>
            </div>
            <div className="capability__content">
              <h4 className="capability__title title title--bold">Project budget estimation</h4>
              <ul className="list capability__description">
                <li>Feature-level estimation</li>
                <li>Resources planning</li>
                <li>Project development plan</li>
              </ul>
              <ul className="budges">
                <li>Business Analyst</li>
                <li>Software Architect</li>
                <li>Project Manager</li>
              </ul>
              <div className="estimate-time"><span>16 h</span></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
