import React from 'react';
import { Link } from 'react-router-dom'

// import assets
import "./styles.scss"

export default function CaseNext (props) {
  const { nextImg, fidelity, title, nextLink } = props;

  return (
    <section className="case-next section section--white">
      <div
        className="case-next__background-wrap"
        style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 7.5e-05) 50%, rgba(0, 0, 0, 0.75) 100%), url(${nextImg})`}}
      >
        <div className="case-next__content section__content">
          <div className="case-next__content-wrap">
            <h5 className="case-next__fidelity title title--bold">{fidelity}</h5>
            <div className="case-next__help-wrap">
              <h2 className="case-next__title title title--bold">{title}</h2>
              <Link to={nextLink} className="case-next__next-link">Next case study</Link>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
