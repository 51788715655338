import React from 'react';

// import assets
import FigmaPrototype from "../../assets/images/FigmaExample.png";
import "./styles.scss"

export default function FigmaExample () {
  return (
    <section className="figma-example section section--red">
      <div className="figma-example__content section__content">
        <header className="figma-example__header">
          <h3 className="title figma-example__title">Get documentation for your project faster and hassle-free</h3>
          <h4 className="title figma-example__subtitle">
            We will prepare full documentation for
            your project using advanced business analysis techniques and UX/UI approach.
          </h4>
        </header>
        <div className="figma-example__image-wrap">
          <img src={FigmaPrototype} className="figma-example__image" alt="Figma example" />
        </div>
      </div>
    </section>
  )
}
