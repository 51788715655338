import React from 'react';

// import assets
import YummieCaseMain from "../../../assets/images/YummieCaseMain.png";
import AuctionCaseNext from "../../../assets/images/AuctionCaseNext.png";
import stage1 from "../../../assets/images/stage1.png"
import stage2 from "../../../assets/images/stage2.png"
import stage3 from "../../../assets/images/YumieGif.gif"
import stage4 from "../../../assets/images/stage4.png"

// import sections
import CaseMain from "../../../screens/CaseMain";
import CaseDescription from "../../../screens/CasesDescription";
import Results from "../../../screens/Results";
import ContactUs from "../../../screens/ContactUs";
import CaseNext from "../../../screens/CaseNext";
import Footer from "../../../components/Footer";

// import constants
import { mainDescription, subDescription } from "./constants"

function YummieCase() {
  return (
    <div className="app">
      <main className="main">
        <CaseMain
          mainImg={YummieCaseMain}
          fidelity="High-fidelity prototype"
          title="Yummies App"
          description="Snacks service supplies offices with Snacks Boxes,  that are placed at the workplace and staff pay for what they eat."
          analyticsFormID="yummie-mainRequestModal"
        />
        <CaseDescription
          mainDescription={mainDescription}
          subDescription={subDescription}
        />
        <section className="stages section section--white">
          <div className="stages__content section__content">
            <header>
              <h2 className="title title--general title--bold stages__title">High fidelity prototype package includes:</h2>
            </header>
            <div className="stages__item stage stage--description-right">
              <div className="stage__image-wrap">
                <img src={stage1} alt="stage 1" className="stage__image" />
              </div>
              <div className="stage__description">
                <div className="stage__number">Stage 1</div>
                <h4 className="title title--bold stage__title">Requirements elicitation</h4>
                <div className="stage__description-text">
                  The work process was started with requirements elicitation. During this activity, we had to understand stakeholder needs and identify potential solutions that may meet those needs.
                  Techniques that we used to conduct elicitation are Market analysis, Interviews, Process analysis, Prototyping.
                  The main requirements that were announced during interviews are:
                </div>
                <ul className="list stage__list">
                  <li>Employee as buyer. Replenish personal account in the app and make payments by taking cash from it, Use barcode to find a snack in the app catalog and buy it, View statistics of snack purchases and see the total sum spent on sweats, Track calories consumption.</li>
                  <li>Office manager as app admin. Add new snacks to the app, see snacks sales statistics.</li>
                </ul>
                <ul className="budges stage__budges">
                  <li>Business Analyst</li>
                  <li>Stakeholder</li>
                </ul>
                <div className="estimate-time stage__estimate">20 h</div>
              </div>
            </div>
            <div className="stages__item stage stage--description-left">
              <div className="stage__image-wrap">
                <img src={stage2} alt="stage 2" className="stage__image"/>
              </div>
              <div className="stage__description">
                <div className="stage__number">Stage 2</div>
                <h4 className="title title--bold stage__title">Specify and Model requirements</h4>
                <div className="stage__description-text">The purpose of this step is to analyze, synthesize, and refine elicitation results into requirements and designs. Using the information received during elicitation we defined high-level features for user roles in the app. The result of this step is a low fidelity prototype.</div>
                <ul className="list stage__list">
                  <li>High-level features were decomposed to smaller and simpler components.</li>
                  <li>To represent activity flow we used User Stories technique.</li>
                  <li>User Stories were organized into User Stories Map.</li>
                  <li>ERD. To represent the relationship between main app entities we used entity relationship diagram.</li>
                  <li>Low fidelity prototype were development and confirmed by the client.</li>
                </ul>
                <ul className="budges stage__budges">
                  <li>Business Analyst</li>
                  <li>UX designer</li>
                </ul>
                <div className="estimate-time stage__estimate">40-60 h</div>
              </div>
            </div>
            <div className="stages__item stage stage--description-right">
              <div className="stage__image-wrap">
                <img src={stage3} alt="stage 3" className="stage__image" style={{ maxWidth: '285px' }} />
              </div>
              <div className="stage__description">
                <div className="stage__number">Stage 3</div>
                <h4 className="title title--bold stage__title">High-fidelity prototype</h4>
                <div className="stage__description-text">Based on requirements we created tasks for the designer and he started work on the high-fidelity prototype. High fidelity prototype includes:</div>
                <ul className="list stage__list">
                  <li>UX/UI development.</li>
                  <li>Interactions between pages and elements.</li>
                </ul>
                <div className="stage__description-text">An interactive prototype that simulates the real system or site's functionality and design details.</div>
                <ul className="budges stage__budges">
                  <li>Business Analyst</li>
                  <li>UX designer</li>
                  <li>Stakeholder</li>
                </ul>
                <div className="estimate-time stage__estimate">15 days</div>
              </div>
            </div>
            <div className="stages__item stage stage--description-left">
              <div className="stage__image-wrap">
                <img src={stage4} alt="stage 4" className="stage__image"/>
              </div>
              <div className="stage__description">
                <div className="stage__number">Stage 4</div>
                <h4 className="title title--bold stage__title">Feature-Based Estimation</h4>
                <div className="stage__description-text">During this process our web engineer estimates resources that need to build product such as:</div>
                <ul className="list stage__list">
                  <li>Frontend</li>
                  <li>Backend</li>
                  <li>QA</li>
                  <li>Project Management</li>
                </ul>
                <div className="stage__description-text">And generates a document that you can review following this link. This document gets you an approximate estimation of cost and time needed for app development.</div>
                <ul className="budges stage__budges">
                  <li>Business Analyst</li>
                  <li>Software Architect</li>
                  <li>Project Manager</li>
                </ul>
                <div className="estimate-time stage__estimate">16 h</div>
              </div>
            </div>
          </div>
        </section>
        <Results
          docs={[
            'Yummies Software Requirements',
            'High-fidelity prototype',
            'Yummies Feature-Based estimation'
          ]}
          docsLink="https://www.dropbox.com/sh/0l82xk8i9jq99gz/AABxF_oK_UGp9PspoDbAvUXna?dl=0"
          figmaLink="https://www.figma.com/file/CddTPpczuqB3X3kXpdGWGD/Brocoders_Yummies-App"
        />
        <ContactUs background={'black'} analyticsFormID="yummie-contactUs" />
        <CaseNext
          title="Auction Marketplace"
          fidelity="Low-fidelity prototype"
          nextImg={AuctionCaseNext}
          nextLink="/auction-case"
        />
        <Footer />
      </main>
    </div>
  );
}

export default YummieCase;
