import React, {useState, useContext} from 'react';
import { Link } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import Modal from "../../components/Modal";
import SuccessPopUp from "../../components/Popups/Success";
import ErrorPopUp from "../../components/Popups/Error";

// helpers
import ClientContext from "../../helpers/context";

// import assets
import {ReactComponent as Logo} from "../../components/svg/Logo.svg";
import IphonePrototype from "../../assets/images/IphonePrototype.png";
import "./styles.scss"

export default function MainSection () {
  const [isOpen, openModal] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const client = useContext(ClientContext);

  const onSubmit = (values, { ...formikProps}) => {
    const resource = {
      ...values,
      name: 'Get prototype form',
      subject: 'other',
      text: '[prototypes.brocoders.com - Get prototype]'
    };

    client.post('/emails', {data: { resource }}, 'https://cases.brocoders.com')
      .then((response) => {
        openModal(true);
        setSuccessSubmit(true);
        formikProps.setSubmitting(false);
      })
      .catch((response) => {
        if (response.error) {
          openModal(true);
          setErrorSubmit(true);
          formikProps.setSubmitting(false);
        }
      });
  };

  return (
    <section className="pr-request section section--black">
      <div className="section__content">
        <header className="pr-request__header">
          <div className="logo">
            <Link to="/" className="logo-link"><Logo /></Link>
          </div>
        </header>
        <div className="pr-request__content-wrap">
          <div className="pr-request__info">
            <h2 className="pr-request__title title title--bold">
              Get an interactive prototype for your project idea</h2>
            <p className="pr-request__requirements-description">
              Receive all the documentation needed to start developing your project
            </p>

            <ul className="pr-request__requirements-list list">
              <li>Software Requirements</li>
              <li>High-fidelity prototype</li>
              <li>Feature-based estimation</li>
            </ul>

            <Formik
              validate={values => {
                let errors = {};
                if (!values.email) { errors.email = 'Required'; }
                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              initialValues={{email: ''}}
              onSubmit={onSubmit}
            >
              {({errors, touched, isSubmitting}) => (
                <Form className="analytics-form__home-requestPrototype">
                  <div className="pr-request__form">
                    <div className="pr-request__form-group">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        className={`input input--bg-grey pr-request__input ${errors.email && touched.email && 'input--error'}`}
                      />
                      <div className="error pr-request__input-error">
                        <span className={`error__text ${errors.email && touched.email ? 'error__text--visible' : ''}`}>
                          {errors.email && touched.email && errors.email}
                        </span>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="button button--default pr-request__get-button analytics-button__home-requestPrototype"
                      disabled={isSubmitting}
                    >
                      Get Prototype
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="pr-request__example">
            <img src={IphonePrototype} alt="Iphone prototype"/>
          </div>
        </div>
      </div>
      <Modal
        show={isOpen}
        onHide={() => openModal(false)}
        size="sm"
        centered
        closePosition='inside'
        headerSideSize='none'
        closeLink
      >
        {successSubmit && (
          <SuccessPopUp title="Request has been sent">
            Our manager will contact you shortly!
          </SuccessPopUp>
        )}

        {errorSubmit && (
          <ErrorPopUp title="Something went wrong!" />
        )}
      </Modal>
    </section>
  )
}
