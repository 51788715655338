import React from 'react';

// import assets
import AuctionCaseMain from "../../../assets/images/AuctionCaseMain.png";
import YummieCaseNext from "../../../assets/images/YummieCaseNext.png";
import auction_stage1 from "../../../assets/images/auction_stage1.png";
import auction_stage2 from "../../../assets/images/auction_stage2.png";
import auction_stage3 from "../../../assets/images/auction_stage3.png";

// import sections
import CaseMain from "../../../screens/CaseMain";
import CaseDescription from "../../../screens/CasesDescription";
import ContactUs from "../../../screens/ContactUs";
import Results from "../../../screens/Results";
import CaseNext from "../../../screens/CaseNext";
import Footer from "../../../components/Footer";

// import constants
import {mainDescription, subDescription} from "./constants";

function AuctionCase() {
  return (
    <div className="app">
      <main className="main">
        <CaseMain
          mainImg={AuctionCaseMain}
          fidelity="Low-fidelity prototype"
          title="Auction Marketplace"
          description="Snacks service supplies offices with Snacks Boxes,  that are placed at the workplace and staff pay for what they eat."
          analyticsFormID="auction-mainRequestModal"
        />
        <CaseDescription
          mainDescription={mainDescription}
          subDescription={subDescription}
        />
        <section className="stages section section--white">
          <div className="stages__content section__content">
            <header>
              <h2 className="title title--general title--bold stages__title">Low fidelity prototype package includes:</h2>
            </header>
            <div className="stages__item stage stage--description-right">
              <div className="stage__image-wrap">
                <img src={auction_stage1} alt="stage 1" className="stage__image" />
              </div>
              <div className="stage__description">
                <div className="stage__number">Stage 1</div>
                <h4 className="title title--bold stage__title">Requirements elicitation</h4>
                <div className="stage__description-text">
                  The work process was started with requirements elicitation. At this stage, the client served as Domain Subject Matter Expert and provided us with the required business information.
                  Techniques that we used to conduct elicitation are:
                </div>
                <ul className="list stage__list">
                  <li>Market analysis. MA is used to determine what customers want and what competitors provide</li>
                  <li>Interviews. Used to ask questions of stakeholders to uncover needs, identify problems, or discover opportunities.</li>
                  <li>Prototyping. Used to elicit and validate stakeholders' needs through an iterative process that creates a model of requirements or designs.</li>
                </ul>
                <ul className="budges stage__budges">
                  <li>Business Analyst</li>
                  <li>Stakeholder</li>
                </ul>
                <div className="estimate-time stage__estimate">20 h</div>
              </div>
            </div>
            <div className="stages__item stage stage--description-left">
              <div className="stage__image-wrap">
                <img src={auction_stage2} alt="stage 2" className="stage__image"/>
              </div>
              <div className="stage__description">
                <div className="stage__number">Stage 2</div>
                <h4 className="title title--bold stage__title">Specify and Model requirements</h4>
                <div className="stage__description-text">The purpose of specifying and modeling requirements is to analyze, synthesize, and refine elicitation results into requirements and designs. Using the information received during elicitation we defined high-level features for user roles in the app.</div>
                <ul className="list stage__list">
                  <li>After that high-level features were decomposed to smaller and simpler components. Functional Decomposition: used to break down solution scope into smaller components for allocation, as well as to trace high-level concepts to low-level concepts.</li>
                  <li>To represent activity flow we used User Stories technique.</li>
                  <li>User Stories: used to specify requirements as a brief statement about what people do or need to do when using the solution.</li>
                  <li>User Stories were organized into User Stories Map</li>
                  <li>ERD. To represent the relationship between main app entities we used entity relationship diagram.</li>
                </ul>
                <ul className="budges stage__budges">
                  <li>Business Analyst</li>
                  <li>UX designer</li>
                </ul>
                <div className="estimate-time stage__estimate">40-60 h</div>
              </div>
            </div>
            <div className="stages__item stage stage--description-right">
              <div className="stage__image-wrap">
                <img src={auction_stage3} alt="stage 3" className="stage__image"/>
              </div>
              <div className="stage__description">
                <div className="stage__number">Stage 3</div>
                <h4 className="title title--bold stage__title">Low-fidelity prototype</h4>
                <div className="stage__description-text">To present requirements in the visual way we used Low fidelity prototype. Low fidelity prototype is a prototype that is sketchy and incomplete, that has some characteristics of the target product but is otherwise simple, usually in order to quickly produce the prototype and test broad concepts.</div>
                <div className="stage__description-text">Low fidelity prototype can be used as</div>
                <ul className="list stage__list">
                  <li>Clarify project requirements</li>
                  <li>Test design ideas</li>
                  <li>Can be used as requirements for the design</li>
                </ul>
                <div className="stage__description-text">An interactive prototype that simulates the real system or site's functionality and design details.</div>
                <ul className="budges stage__budges">
                  <li>Business Analyst</li>
                  <li>UX designer</li>
                  <li>Stakeholder</li>
                </ul>
                <div className="estimate-time stage__estimate">1 week</div>
              </div>
            </div>
          </div>
        </section>
        <Results
          docs={['Auction SRS', 'Low fidelity prototype']}
          docsLink="https://www.dropbox.com/sh/igxdyouwq30qbjd/AADpblbnCgzUN5UCf5x7cAYLa?dl=0"
          figmaLink="https://www.figma.com/file/YslV4bTl1UICTYkPD3WBDJ/Brocoders_Auction-Marketplace"
        />
        <ContactUs background={'black'} analyticsFormID="auction-contactUs" />
        <CaseNext
          title="Yummies App"
          fidelity="High-fidelity prototype"
          nextImg={YummieCaseNext}
          nextLink="/yummies-case"
        />
        <Footer />
      </main>
    </div>
  );
}

export default AuctionCase;