import React, {useState} from 'react';
import Modal from "../../components/Modal"
import { Link } from "react-router-dom";

import OrderForm from "../../components/Forms/OrderForm"

// import assets
import {ReactComponent as Logo} from "../../components/svg/Logo.svg";
import "./styles.scss"

export default function CaseMain (props) {
  const { mainImg, fidelity, title, description, analyticsFormID } = props;
  const [isOpen, openModal] = useState(false);

  return (
    <section className="case-main section section--white">
      <div
        className="case-main__background-wrap"
        style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 7.5e-05) 40%, rgba(0, 0, 0, 0.75) 100%), url(${mainImg})`}}
      >
        <div className="case-main__content section__content">
          <header className="case-main__header">
            <div className="logo">
              <Link to="/" className="logo-link"><Logo /></Link>
            </div>
          </header>
          <div className="case-main__content-wrap">
            <h5 className="case-main__fidelity title title--bold">{fidelity}</h5>
            <h2 className="case-main__title title title--bold">{title}</h2>
            <div className="case-main__description">{description}</div>
            <div className="case-main__form">
              <button
                type="button"
                className="button button--default case-main__get-button"
                onClick={() => openModal(true)}
              >
                Get Prototype
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isOpen}
        onHide={() => openModal(false)}
        size="lg"
        centered
        closePosition='inside'
        headerSideSize='none'
        bodySideSize='none'
      >
        <OrderForm analyticsFormID={analyticsFormID}/>
      </Modal>
    </section>
  )
}
