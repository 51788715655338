import React from 'react';
import ReactDOM from 'react-dom';

// react-router components
import { BrowserRouter as Router } from "react-router-dom";

import App from './pages/App/App';

// helpers
import ApiClient from "./helpers/ApiClient";
import ClientContext from "./helpers/context";

// utils
import * as serviceWorker from './serviceWorker';

const client = new ApiClient();

ReactDOM.render(
  <ClientContext.Provider value={client}>
    <Router>
      <App />
    </Router>
  </ClientContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
