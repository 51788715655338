import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { CSSTransition } from 'react-transition-group';

// import assets
import {ReactComponent as LoaderLogo} from "../../components/svg/LoaderLogo.svg";
import './styles.scss';

const RouteWithTransition = (RouteComponent) => {
  function Wrapper(props) {
    const { location: { pathname } } = props;
    const [isLoading, setLoading] = useState(true);

    const timeoutRef = useRef();

    useEffect(() => {
      setLoading(true);
      timeoutRef.current = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timeoutRef.current);
    }, [pathname]);

    return (
      <>
        <CSSTransition
          classNames="loader"
          in={isLoading}
          timeout={1000}
          appear
          unmountOnExit
          onEnter={() => document.body.classList.add('no-scroll')}
          onEntering={() => window.scrollTo(0, 0)}
          onExit={() => document.body.classList.remove('no-scroll')}
        >
          <div className="loader">
            <div className="loader__logo">
              <LoaderLogo />
            </div>
            <div className='loader__progress' />
          </div>
        </CSSTransition>
        <div style={{ opacity: isLoading ? '0' : '1', transition: 'opacity 1s'}}>
          <RouteComponent {...props} />
        </div>
      </>
    );
  }

  return withRouter(Wrapper);
};

export default RouteWithTransition;
