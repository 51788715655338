import React, { useState } from 'react';
import Modal from '../../components/Modal'

// import assets
import VideoPlaceholder from "../../assets/images/Video_Placeholder.png";
import "./styles.scss"

const youtubeConfig = {
  autoplay: 1,
  mute: 0,
  cc_load_policy: 1,
  controls: 1,
  disablekb: 0,
  enablejsapi: 0,
  end: null,
  fs: 1,
  h1: null,
  iv_load_policy: 1,
  list: null,
  listType: null,
  loop: 0,
  modestbranding: 0,
  origin: null,
  playlist: null,
  playsinline: null,
  rel: 0,
  showinfo: 1,
  start: 0,
  wmode: 'transparent',
  theme: 'dark'
};

export default function VideoSection () {
  const [isOpen, openModal] = useState(false);

  const getYoutubeQueryString = (obj) => {
    let url = '';
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== null) {
          url += key + '=' + obj[key] + '&'
        }
      }
    }
    return url.substr(0, url.length - 1)
  };

  const getYoutubeUrl = () => {
    const query = getYoutubeQueryString(youtubeConfig);
    return 'https://www.youtube.com/embed/Bi_EMKqJ1CI?' + query
  };

  return (
    <section className="video-section section section--grey">
      <div className="section__content video-section__content">
        <header className="video-section__header">
          <h2 className="title title--general title--bold">Why you need a prototype</h2>
          <h4 className="title title--sub">Watch this short video to know the benefits of the prototype</h4>
        </header>
        <div className="video">
          <div className="video__placeholder-wrap">
            <img src={VideoPlaceholder} className="video__placeholder-img" alt="Video placeholder" />
            <div className="video__play-wrap" onClick={() => openModal(true)}>
              <div className="video__play-btn"/>
            </div>
          </div>
        </div>
        <Modal
          show={isOpen}
          onHide={() => openModal(false)}
          size="xl"
          centered
          closeOutside
          headerSideSize='none'
          bodySideSize='none'
        >
          <div className="video-movie-wrap">
            <iframe
              width='460'
              height='230'
              id="ytplayer"
              type="text/html"
              src={getYoutubeUrl()}
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay"
              tabIndex='-1'
              title="Brocoders video"
            />
          </div>
        </Modal>
      </div>
    </section>
  )
}