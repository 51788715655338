import React from 'react';
import PopUp from '../';

// import assets
import ErrorIcon from "../../../components/svg/Icons/ErrorIcon.svg";

export default function ErrorPopUp (props) {
  return (
    <PopUp
      icon={ErrorIcon}
      {...props}
    />
  )
}
