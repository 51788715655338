import React from 'react';

// import assets
import FigmaViewer from "../../components/svg/Icons/Figma-viewer.svg";
import PdfViewer from "../../components/svg/Icons/Pdf-viewer.svg";
import CheckIn from "../../components/svg/Icons/Check-in.svg";
import "./styles.scss"

export default function Results (props) {
  const { docs = [], docsLink='#', figmaLink='#' } = props;

  return (
    <section className="results section section--red">
      <div className="results__content section__content">
        <header>
          <h2 className="title title--general title--white title--bold">Results</h2>
        </header>
        <div className="results__wrap">
          <div className="results__docs-list-wrap">
            <div className="results__docs-title">Finally, the client received next documents</div>
            <ul className="results__docs-list">
              {docs.map((doc, index) => (
                <li key={index}>
                  <span className='results__docs-list-icon'><img src={CheckIn} alt="check in icon"/></span>
                  <span className='results__docs-list-text'>{doc}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="results__docs-links-wrap">
            <div className="results__doc-link">
              <span className="results__docs-icon"><img src={PdfViewer} alt="figma viewer icon"/></span>
              <a href={docsLink} target='_blank' rel='noopener noreferrer' className="results__docs-link-text">Review documents set</a>
            </div>
            <div className="results__doc-link">
              <span className="results__docs-icon"><img src={FigmaViewer} alt="figma viewer icon"/></span>
              <a href={figmaLink} target='_blank' rel='noopener noreferrer' className="results__docs-link-text">Review prototype</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
