import React from 'react';

// import assets
import "./styles.scss"

export default function PopUp (props) {
  const {
    children,
    title,
    icon: Icon
  } = props;

  return (
    <div className="popup popup--side-md popup--end-md">
      <div className="popup__icon">
        <img src={Icon} width="50px" height="50px" alt="popup icon" />
      </div>
      <h4 className="title title--bold popup__title">{title}</h4>
      <div className="popup__text">
        {children}
      </div>
    </div>
  )
}
