import React, {useState, useContext}  from 'react';
import { Formik, Form, Field } from 'formik';
import ClientContext from "../../../helpers/context";

import SuccessPopUp from "../../Popups/Success";
import ErrorPopUp from "../../Popups/Error";

// import assets
import "./styles.scss"

export default function OrderForm (props) {
  const { selectedPricingPlan = "", analyticsFormID } = props;
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const client = useContext(ClientContext);

  const onSubmit = (values, { ...formikProps}) => {
    const resource = {
      ...values,
      subject: 'other',
      text: `[prototypes.brocoders.com - ${values.pricing_plan ? values.pricing_plan : 'Get prototype'}] 
      ${values.text}`
    };

    client.post('/emails', {data: { resource }}, 'https://cases.brocoders.com').then((response) => {
      setSuccessSubmit(true);
      formikProps.setSubmitting(false);
    }).catch((response) => {
      if (response.error) {
        setErrorSubmit(true);
        formikProps.setSubmitting(false);
      }
    });
  };

  return (
    <div className="order-form">
      <div className="order-form__img-wrap" />
      <div className="order-form__content-wrap">
        {
          !successSubmit && !errorSubmit && (
            <>
              <h4 className="title title--bold order-form__title">Get a prototype for your project</h4>
              <div className="order-form__sub-title">Fill out the form and our manager will contact you shortly</div>
            </>
          )
        }
        <Formik
          validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Please enter your email address';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Please enter a valid email address';
            }
            if (!values.name) errors.name = 'Please enter your name';

            return errors;
          }}
          initialValues={{
            pricing_plan: selectedPricingPlan || '',
            name: '',
            email: '',
            text: ''
          }}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting
          }) => (
          <Form className={`analytics-form__${analyticsFormID}`}>
            {successSubmit && (
              <SuccessPopUp title="Request has been sent">
                Our manager will contact you shortly!
              </SuccessPopUp>
            )}

            {errorSubmit && (
              <ErrorPopUp title="Something went wrong!" />
            )}

            {
              !successSubmit && !errorSubmit && (
                <div className="order-form__form">
                  <div className="order-form__form-group">
                    <Field
                      name="name"
                      className="input input--bg-light-grey input--color-grey"
                      placeholder="Name"
                    />
                    <div className="error">
                      <span className={`error__text ${errors.name && touched.name ? 'error__text--visible' : ''}`}>
                        {errors.name && touched.name && errors.name}
                      </span>
                    </div>
                  </div>
                  <div className="order-form__form-group">
                    <Field
                      name="email"
                      placeholder="Email"
                      className="input input--bg-light-grey input--color-grey"
                    />
                    <div className="error">
                      <span className={`error__text ${errors.email && touched.email ? 'error__text--visible' : ''}`}>
                        {errors.email && touched.email && errors.email}
                      </span>
                    </div>
                  </div>
                  <div className="order-form__form-group">
                    <div className="order-form__field-description">
                      Need NDA first? E-mail us at <a href="mailto:talk@brocoders.team">talk@brocoders.team</a>
                    </div>
                    <Field
                      component="textarea"
                      rows="8"
                      name="text"
                      placeholder="Describe your project idea"
                      className="input input--bg-light-grey input--color-grey input--br-sm"
                    />
                  </div>
                  <button
                    type="submit"
                    className={`button button--default order-form__submit-btn analytics-button__${analyticsFormID}`}
                    disabled={isSubmitting}
                  >
                    Send
                  </button>
                </div>
              )
            }
          </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
