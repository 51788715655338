import React from 'react';
import PopUp from '../index';

// import assets
import SuccessIcon from "../../../components/svg/Icons/SuccessIcon.svg";

export default function SuccessPopUp (props) {
  return (
    <PopUp
      icon={SuccessIcon}
      {...props}
    />
  )
}
