import React from 'react';

// import assets
import Desktop1 from "../../assets/images/FigmaExamples/Desktop1.png";
import Desktop2 from "../../assets/images/FigmaExamples/Desktop2.png";
import Desktop4 from "../../assets/images/FigmaExamples/Desktop4.png";
import Desktop3 from "../../assets/images/FigmaExamples/Desktop3.png";
import Desktop5 from "../../assets/images/FigmaExamples/Desktop5.png";
import Desktop6 from "../../assets/images/FigmaExamples/Desktop6.png";
import Desktop7 from "../../assets/images/FigmaExamples/Desktop7.png";
import Desktop8 from "../../assets/images/FigmaExamples/Desktop8.png";
import "./styles.scss"

export default function PrototypeExamples () {
  return (
    <section className="section">
      <div className="section__content">
        <div className="pr-examples">
          <div className="pr-examples__row pr-examples__row--top">
            <div className="pr-examples__item">
              <img src={Desktop1} className="pr-examples__image" alt="Prototype Figma example 1" />
            </div>
            <div className="pr-examples__item">
              <img src={Desktop2} className="pr-examples__image" alt="Prototype Figma example 2" />
            </div>
            <div className="pr-examples__item">
              <img src={Desktop4} className="pr-examples__image" alt="Prototype Figma example 3" />
            </div>
            <div className="pr-examples__item">
              <img src={Desktop3} className="pr-examples__image" alt="Prototype Figma example 4" />
            </div>
          </div>
          <div className="pr-examples__row pr-examples__row--btm">
            <div className="pr-examples__item">
              <img src={Desktop5} className="pr-examples__image" alt="Prototype Figma example 5" />
            </div>
            <div className="pr-examples__item">
              <img src={Desktop6} className="pr-examples__image" alt="Prototype Figma example 6" />
            </div>
            <div className="pr-examples__item">
              <img src={Desktop7} className="pr-examples__image" alt="Prototype Figma example 7" />
            </div>
            <div className="pr-examples__item">
              <img src={Desktop8} className="pr-examples__image" alt="Prototype Figma example 8" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}