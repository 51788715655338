import React, {useState, useContext} from 'react';
import { Formik, Form, Field } from 'formik';
import Modal from "../../components/Modal";
import SuccessPopUp from "../../components/Popups/Success";

// helpers
import ClientContext from "../../helpers/context";

// import assets
import "./styles.scss"
import ErrorPopUp from "../../components/Popups/Error";

export default function ContactUs (props) {
  const { background, analyticsFormID } = props;
  const [isOpen, openModal] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const client = useContext(ClientContext);

  const onSubmit = (values, { ...formikProps}) => {
    const resource = {
      ...values,
      name: 'Get prototype form',
      subject: 'other',
      text: '[prototypes.brocoders.com - Get prototype]'
    };

    client.post('/emails', {data: { resource }}, 'https://cases.brocoders.com')
      .then((response) => {
        openModal(true);
        setSuccessSubmit(true);
        formikProps.setSubmitting(false);
      })
      .catch((response) => {
        if (response.error) {
          openModal(true);
          setErrorSubmit(true);
          formikProps.setSubmitting(false);
        }
      });
  };

  return (
    <section className={`contact-us section  section--${background}`}>
      <div className="section__content contact-us__content">
        <header className="contact-us__header">
          <h2 className="title title--bold contact-us__title">Contact us to kick-start your next big idea</h2>
          <h4 className="title title--sub contact-us__sub-title">We will get back to you within 24 hours</h4>
        </header>
        <Formik
          validate={values => {
            let errors = {};
            if (!values.email) { errors.email = 'Required'; }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          initialValues={{email: ''}}
          onSubmit={onSubmit}
        >
          {({errors, touched, isSubmitting}) => (
            <Form className={`analytics-form__${analyticsFormID}`}>
              <div className="contact-us__form">
                <div className="contact-us__form-group">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className={`
                      input input--${background === 'black' ? 'bg-grey' : 'bg-red'} 
                      ${errors.email && touched.email && 'input--error'} 
                      contact-us__input`
                    }
                  />
                  <div className="error contact-us__input-error">
                    <span className={`error__text ${errors.email && touched.email ? 'error__text--visible' : ''}`}>
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                </div>

                <button
                  type="submit"
                  className={`button button--default button--${background === 'black' ? 'red' : 'black'} contact-us__get-button analytics-button__${analyticsFormID}`}
                  disabled={isSubmitting}
                >
                  Get Prototype
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        show={isOpen}
        onHide={() => openModal(false)}
        size="sm"
        centered
        closePosition='inside'
        headerSideSize='none'
        closeLink
      >
        {successSubmit && (
          <SuccessPopUp title="Request has been sent">
            Our manager will contact you shortly!
          </SuccessPopUp>
        )}

        {errorSubmit && (
          <ErrorPopUp title="Something went wrong!" />
        )}
      </Modal>
    </section>
  )
}
