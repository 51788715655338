import React, {useState} from 'react';
import Modal from "../../components/Modal"

import OrderForm from "../../components/Forms/OrderForm";

// import assets
import "./styles.scss"

export default function Prices () {
  const [isOpen, openModal] = useState(false);
  const [pricingPlan, changePricingPlan] = useState("");

  const selectPricingPlan = pricingPlan => {
    changePricingPlan(pricingPlan);
    openModal(true);
  };

  return (
    <section className="price-section section section--white">
      <div className="section__content price-section__content">
        <header>
          <h2 className="title title--general title--bold">Prototype Pricing Plans</h2>
          <h4 className="title title--sub">Learn more about packages we propose</h4>
        </header>
        <div className="price-list">
          <div className="price-list__item price-card">
            <div className="price-card__header">
              <h4 className="price-card__title">Low-fidelity</h4>
              <div className="price-card__price">$1000</div>
            </div>
            <div className="price-card__content">
              <div className="price-card__proposals">
                <div className="price-card__proposal-item">Requirements elicitation</div>
                <div className="price-card__proposal-item">Specifying and modeling requirements</div>
                <div className="price-card__proposal-item">Low fidelity prototype for 10 main screens</div>
              </div>
              <button
                className="button button--default button--black button--side-md"
                onClick={() => selectPricingPlan("$1000 - Low-fidelity prototype")}
              >
                Select
              </button>
            </div>
          </div>
          <div className="price-list__item price-card">
            <div className="price-card__header">
              <h4 className="price-card__title">High-fidelity</h4>
              <div className="price-card__price">from $3000</div>
            </div>
            <div className="price-card__content">
              <div className="price-card__proposals">
                <div className="price-card__proposal-item">Requirements elicitation</div>
                <div className="price-card__proposal-item">Specifying and modeling requirements</div>
                <div className="price-card__proposal-item">High-fidelity prototype</div>
                <div className="price-card__proposal-item">Feature-based estimation</div>
              </div>
              <button
                type="button"
                className="button button--default button--black button--side-md"
                onClick={() => selectPricingPlan("from $3000 - High-fidelity prototype")}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isOpen}
        onHide={() => openModal(false)}
        size="lg"
        centered
        closePosition='inside'
        headerSideSize='none'
        bodySideSize='none'
      >
        <OrderForm selectedPricingPlan={pricingPlan} analyticsFormID={`${pricingPlan.split(" ").join("-")}`} />
      </Modal>
    </section>
  )
}
