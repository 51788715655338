import React from 'react';

// react-router components
import { Switch, Route, Redirect } from "react-router";
import RouteWithTransition from "../../hoc/RouteWithTransition";

import './App.scss';

// application pages
import Home from "../Home";
import YummieCase from "../Cases/Yummie-case"
import AuctionCase from "../Cases/Auction-case"

function App() {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={RouteWithTransition(Home)} />
        <Route exact path="/yummies-case" component={RouteWithTransition(YummieCase)} />
        <Route exact path="/auction-case" component={RouteWithTransition(AuctionCase)} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
